import { IRouteConfig, IRoutingConfig } from '../../_shared/models/models-index';

export class RoutingConfig implements IRoutingConfig {

  public routes: IRouteConfig[] = [
    {
      id: 'logout',
      category: 'logout',
      iconClass: null,
      displayName: null,
      displayInNav: false,
      defaultRoute: '/logout',
      roles: undefined,
      children: [],
      routeData: {
        content: [
          'You have been successfully logged out. Please close this browser window.'
        ]
      }
    },
    {
      id: 'leads',
      category: 'leads',
      default: true,
      defaultRoute: '/leads/overview',
      displayName: 'Leads',
      displayInNav: true,
      iconClass: 'fa fa-users',
      roles: undefined,
      children: [
        {
          parent: '/leads',
          route: 'overview',
          displayName: 'Overview',
          iconClass: 'fa fa-users',
          roles: undefined,
          breadcrumbHelpEnabled: true
        },
        {
          parent: '/leads',
          route: 'transactions',
          displayName: 'Transactions',
          iconClass: 'fa fa-exchange',
          roles: undefined,
          breadcrumbHelpEnabled: true
        },
        // {
        //     parent: '/leads',
        //     route: 'process-performance',
        //     displayName: 'Process Performance',
        //     iconClass: 'fa fa-compass',
        //     roles: undefined,
        //     breadcrumbHelpEnabled: true
        // }
      ]
    },
    {
      id: 'sales',
      category: 'sales',
      defaultRoute: '/sales/overview',
      displayName: 'Sales',
      displayInNav: true,
      iconClass: 'fa fa-usd',
      roles: undefined,
      children: [
        {
          parent: '/sales',
          route: 'overview',
          displayName: 'Overview',
          pdfEnabled: false,
          iconClass: 'fa fa-users',
          roles: undefined,
          breadcrumbHelpEnabled: true
        }
      ]
    },
    {
      id: 'externalTools',
      category: 'externalTools',
      defaultRoute: '',
      displayName: 'External Tools',
      displayInNav: true,
      iconClass: 'fa fa-external-link',
      roles: undefined,
      externalTool: true,
      disableForDealerGroups: true,
      children: [
        {
          parent: '/externalTools',
          route: 'retailerTools',
          displayName: 'Dealer Tools',
          iconClass: 'fa fa-wrench',
          roles: undefined,
          breadcrumbHelpEnabled: true
        }
      ]
    },
    {
      id: 'resources',
      category: 'resources',
      defaultRoute: '/resources/resources',
      displayName: 'Resources',
      displayInNav: true,
      iconClass: 'fa fa-wrench',
      roles: ['System Administrator', 'System Analyst', 'Corporate'],
      disableForDealerGroups: true,
      children: [
        {
          parent: '/resources',
          route: 'resources',
          displayName: 'Resources',
          iconClass: 'fa fa-wrench',
          roles: ['System Administrator', 'System Analyst', 'Corporate'],
        }
      ]
    },
    {
      id: 'management',
      category: 'management',
      defaultRoute: '/management/users',
      displayName: 'Management',
      displayInNav: true,
      iconClass: 'fa fa-cog',
      roles: ['System Administrator', 'System Analyst'],
      children: [
        {
          parent: '/management',
          route: 'content',
          displayName: 'Content',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', 'System Analyst']
        }, {
          parent: '/management',
          route: 'report-views-configuration',
          displayName: 'Report Views',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', 'System Analyst'],
          children:
          [
            {
              id: 'report-views-configuration/create',
              parent: 'report-views-configuration',
              route: 'create',
              displayName: 'Create Report View',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'report-views-configuration/update',
              parent: 'report-views-configuration',
              route: 'update',
              displayName: 'Edit Report View',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        }, {
          parent: '/management',
          route: 'data-sets-configuration',
          displayName: 'Data Sets',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', 'System Analyst'],
          children:
          [
            {
              id: 'data-sets-configuration/create',
              parent: 'data-sets-configuration',
              route: 'create',
              displayName: 'Create Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'data-sets-configuration/update',
              parent: 'data-sets-configuration',
              route: 'update',
              displayName: 'Edit Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        }, {
          parent: '/management',
          route: 'query-configuration',
          displayName: 'Queries',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', 'System Analyst'],
          children:
          [
            {
              id: 'query-configuration/create',
              parent: 'query-configuration',
              route: 'create',
              displayName: 'Create Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'query-configuration/update',
              parent: 'query-configuration',
              route: 'update',
              displayName: 'Edit Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        },
        // {
        //   parent: '/management',
        //   route: 'filter-configuration',
        //   displayName: 'Filters',
        //   iconClass: 'fa fa-code',
        //   roles: ['System Administrator', "System Analyst"],
        //   children:
        //   [
        //     {
        //       id: 'filter-configuration/create',
        //       parent: 'filter-configuration',
        //       route: 'create',
        //       displayName: 'Create Filter',
        //       iconClass: 'fa fa-code',
        //       roles: ['System Administrator'],
        //     },
        //     {
        //       id: 'filter-configuration/update',
        //       parent: 'filter-configuration',
        //       route: 'update',
        //       displayName: 'Edit Filter',
        //       iconClass: 'fa fa-code',
        //       roles: ['System Administrator'],
        //     },
        //   ]
        // },
        {
          parent: '/management',
          route: 'users',
          displayName: 'Users',
          iconClass: 'fa fa-users',
          roles: ['System Administrator', 'System Analyst']
        }
      ]
    }
  ];
}
